/* map
––––––––––––––––––––––––––––––––––––––––––––––––– */
.map-content {
  padding: 2rem 0;
  @media(min-width: $tablet) {
    padding: 2rem 2rem;
  }
  .map-wrapper {
    .wrapper {
      padding: 0 2rem;
    }
  }
  
  #map {
    height: 20rem;
    @media(min-width: $tablet) {
      height: 50rem;
    }  
  }
  .ingress {
    max-width: 60rem;
    margin: 0 auto;
    padding-bottom: 2rem;
  }
  .contact-info {
    max-width: 60rem;
    margin: 0 auto;
    padding-bottom: 5rem;
    color: $green;
    p {
      margin: 0;
    }
  }
  .office-boxes {
    margin: 6rem 0;
    @include displayflex;
    flex-flow: column nowrap;
    text-align: left;
    @media(min-width: $tablet) {
      flex-flow: row nowrap;
    }
    .office-list-item {
      padding: 1.5rem 0;
      display: block;
      h3 {
        font-size: 1.9rem;
      }
      p {
        display: block;
        color: $green;
      }
      @media(min-width: $tablet) {
        margin: 0;
        padding: 1.5rem;
        @include displayflex;
        flex-wrap: wrap;
      }
    }
  }
}