/* popup styles
––––––––––––––––––––––––––––––––––––––––––––––––– */
.modal {
  display: none;
  position: fixed; 
  z-index: 999;
  padding-top: 10rem;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
}

/* Modal Content */
.modal-content {
  position: relative;
  margin: auto;
  width: 80%;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;

  .modal-wrapper {
    padding: 2rem;
    background-color: $black;
    max-width: 70rem;
    margin: 0 auto;
  }

  .hbspt-form {
    text-align: left;
    margin: 0 auto;
    max-width: 67rem;
  }
}
/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-30rem; opacity:0} 
  to {top:0; opacity:1}
}
@keyframes animatetop {
  from {top:-30rem; opacity:0}
  to {top:0; opacity:1}
}
/* The Close Button */
.modal-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: $green;
  float: right;
  font-size: 4.7rem;
  font-weight: bold;
  @include transition(all .3s ease);
}
.modal-close:hover,
.modal-close:focus {
  color: $white;
  text-decoration: none;
  cursor: pointer;
}