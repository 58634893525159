/* import fonts
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@font-face {
  font-family: "Roboto-Light";
  font-display: swap;
  src: url("assets/fonts/Roboto/Roboto-Light.ttf") format('truetype');
}

@font-face {
  font-family: "Roboto-Regular";
  font-display: swap;
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Roboto-Bold";
  font-display: swap;
  src: url("assets/fonts/Roboto/Roboto-Bold.ttf") format('truetype');
}

@font-face {
  font-family: "Roboto-Black";
  font-display: swap;
  src: url("assets/fonts/Roboto/Roboto-Black.ttf") format('truetype');
}
/* Replica */
@font-face {
  font-family: 'Replica-Regular';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('assets/fonts/Replica/ReplicaProTT.eot'); /* IE9 Compat Modes */
  src: local('Replica'), local('Replica'),
       url('assets/fonts/Replica/ReplicaProTT.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/Replica/ReplicaProTT.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/Replica/ReplicaProTT.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/Replica/ReplicaProTT.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/Replica/ReplicaProTT.svg#Replica') format('svg'); /* Legacy iOS */
}
/* Replica */
@font-face {
  font-family: 'Replica-Bold';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('assets/fonts/Replica/ReplicaProTT-Bold.eot'); /* IE9 Compat Modes */
  src: local('Replica-Bold'), local('Replica-Bold'),
       url('assets/fonts/Replica/ReplicaProTT-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/Replica/ReplicaProTT-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/Replica/ReplicaProTT-Bold.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/Replica/ReplicaProTT-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/Replica/ReplicaProTT-Bold.svg#Replica') format('svg'); /* Legacy iOS */
}
/* Replica */
@font-face {
  font-family: 'Replica-Heavy';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('assets/fonts/Replica/ReplicaProTT-Heavy.eot'); /* IE9 Compat Modes */
  src: local('Replica-Heavy'), local('Replica-Heavy'),
       url('assets/fonts/Replica/ReplicaProTT-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/Replica/ReplicaProTT-Heavy.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/Replica/ReplicaProTT-Heavy.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/Replica/ReplicaProTT-Heavy.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/Replica/ReplicaProTT-Heavy.svg#Replica') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('assets/fonts/Montserrat/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('assets/fonts/Montserrat/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/Montserrat/montserrat-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/Montserrat/montserrat-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/Montserrat/montserrat-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/Montserrat/montserrat-v12-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('assets/fonts/Montserrat/montserrat-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('assets/fonts/Montserrat/montserrat-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/Montserrat/montserrat-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/Montserrat/montserrat-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/Montserrat/montserrat-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/Montserrat/montserrat-v12-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* Fonts styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* NOTE
html is set to 62.5% so that all the REM measurements throughout scss are based on 10px sizing. 
So basically 1.5rem = 15px */

html {
  font-size: 62.5%;
  @include box-sizing;
  *, *:before, *:after {
    @include box-sizing;
  }

  // &.site-loaded {
  //   @include transition(.1s ease-in-out);
  //   background: none;
  // }

}

body {
  font-size: 1.4rem;
  line-height: 1.6;
  font-weight: 400;
  font-family: $fontprimary;
  color: $lightgrey;
  background: $black;
  text-align: center;
  // opacity: 0;
  @include transition(.2s ease-in-out);

  // .site-loaded & {
  //   opacity: 1;
  // }

  @media (min-width: 550px) {
    font-size: 1.6rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: $fontheadingbold;
  text-transform: uppercase;
  color: $white;
}

h1 {
  font-size: 4.0rem;
  line-height: 1.2;

  @media (min-width: 550px) {
    font-size: 5.0rem;
  }
}

h2 {
  font-size: 3rem;
  line-height: 1.25;
  word-break: break-word;
  
  @media (min-width: 550px) {
    font-size: 4rem;
  }
}

h3 {
  font-size: 2.5rem;
  line-height: 1.3;

  @media (min-width: 550px) {
    font-size: 3rem;
  }
}

h4 {
  font-size: 2.0rem;
  line-height: 1.35;

  @media (min-width: 550px) {
    font-size: 2.0rem;
  }
}

h5 {
  font-size: 1.8rem;
  line-height: 1.5;

  @media (min-width: 550px) {
    font-size: 1.7rem;
  }
}

h6 {
  font-size: 1.6rem;
  line-height: 1.6;
}

p {
  margin-top: 0;
  margin-bottom: 2.5rem;
}

b,
strong {
  font-family: $fontprimary;
  font-weight: 600;
}

blockquote {
  display: block;
  font-size: 1.5rem;
  font-style: italic;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 4.0rem;
  -webkit-margin-end: 4.0rem;
  @media (min-width: $tablet) {
    font-size: 2rem;
  }
}