.cookie-message {
  position: fixed;
  display: none;
  z-index: 99;
  bottom: 0;
  right: 0;
  padding: 1rem;
  max-width: 100rem;
  color: $white;
  background: darken($blue, 3);
  @media(min-width: $tablet) {
    padding: 1.6rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    margin-left: 0rem;
    cursor: pointer;
    display: block;
    margin-top: 1rem;
    text-align: center;
    @media(min-width: $tablet) {
      display: inline-block;
    }
  }
}

.close-cookie {
  font-size: 1.3rem;
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 1.3rem 2.5rem;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: $green;
  border: .1rem solid $green;
  outline: none;
  background: darken($blue, 3);
  @include transition(all .23s ease-in-out);
  @media(min-width: $tablet) {
    width: auto;
  }
  &:hover {
    text-decoration: none;
    color: $blue;
    background: $green;
  }
}