.bullet-list {
  .bullet-list-wrap {
    position: relative;
    z-index: 2;
    padding: 4rem 2rem 0 2rem;
    flex-flow: column nowrap;
    flex-direction: column;
    text-align: left;
    @include displayflex;
    
    @media(min-width: $tablet) {
      padding: 6rem 2rem;
      flex-flow: row wrap;
    }
    h2 {
      padding: 0 0 4rem 0;
    }
    h2,
    .ingress {
      display: block;
      width: 100%;
      margin: 0 auto;
      text-align: left;
    }
    .ingress {
      padding: 1rem 0 5rem 0;
    }

    /* Box */
    .box-wrap {
      text-align: left;
      z-index: 3;
      @include flex(0 1 auto);
      padding: 0 5rem 0 0;
      margin: 0 0 3rem 0;
      @include transition(all .23s ease-in-out);

      @media(min-width: $tablet) {
        // width: 33.33%;
        margin: 0 0 2rem 0;
      }

     }

    h3 {
      position: relative;
      font-family: $fontheadingregular;
      font-size: 1.6rem;
      color: $green;
    }

    .text {
      font-family: $fontheadingregular;
      font-size: 1.8rem;
      line-height: 1;
      color: $green;
      margin: 1rem 0 2rem 0;

      @media(min-width: $tablet) {
        font-size: 2rem;
      }
    }
  }
  .count-3, .count-5, .count-6, .count-7, .count-9, .count-11 {
    .box-wrap {
      @media(min-width: $tablet) {
        width: 33%;
      }
    }
  }
  .count-2, .count-4, .count-8, .count-10, .count-12 {
    max-width: 110rem;

    .box-wrap {
      @media(min-width: $tablet) {
        width: 50%;
      }
    }
  }
}