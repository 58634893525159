/* front hero styles
––––––––––––––––––––––––––––––––––––––––––––––––– */

.home {
  .banner {
    height: calc(100vh - 6rem);
    min-height: 30rem;
    @media (min-width: $tablet) {
      min-height: 70rem;
      height: calc(80vh - 12rem);
    }
    .banner-overlay {
      opacity: 1;
      @media (min-width: $tablet) {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 40%, rgba(255, 255, 255, 0) 90%);
      }
    }
  }
  h1 {
    color: $black;
  }
}

.banner {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 45rem;
  margin-bottom: 0;
  color: $lightgrey;
  text-align: left;
  overflow: hidden;

  @media (min-width: $tablet) {
    min-height: 58rem;
  }

  .mockup-image-wrapper {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  .mockup-image {
    position: absolute;
    z-index: 0;
    opacity: .5;
    top: 0rem;
    right: 0;
    height: 100%;
    width: 60%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    @include transition(all .83s ease-in-out);

    @media (min-width: $tablet) {
      opacity: 1;
      right: auto;
      left: 50%;
      width: 40vw;
      max-width: 70rem;
    }
  }

  .banner-image {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include transition(all .83s ease-in-out);
  }
  
  h1 {
    color: $white;

    @media (min-width: $tablet) {
      font-size: 6.8rem;
    }
  }

  p {
    text-shadow: 0 .2rem .2rem rgba(0, 0, 0, 0.1);
    font-size: 1.6rem;
    color: darken($white, 15);
  }

  .small-heading {
    color: $green;
    font-family: $fontheading;
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  // Bg color
  .banner-bg-color {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
 // Overlay color
  .banner-overlay {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: rgba(0, 0, 0, 0.6);
    
    @media (min-width: $tablet) {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 40%, rgba(255, 255, 255, 0) 90%);
    }
  }

  .btn-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 37rem;

    @media (min-width: $tablet) {
      flex-direction: row;
      max-width: 100%;
    }
  }

  .btn {
    margin: 0 1rem 1.5rem 0;
  }

  .banner-wrapper {
    margin: 0 auto;

    @media (min-width: $tablet) {
      max-width: 120rem;
    }
    @media (min-width: $desktop) {
      max-width: 120rem;
    }
    @media (min-width: $desktop-l) {
      max-width: 150rem;
    }
  }

  .content-wrap {
    position: relative;
    z-index: 1;
    top: 7rem;
    padding: 0 2rem;
    margin-bottom: 20rem;
    opacity: 0;

    @media (min-width: $tablet) {
      position: relative;
      width: 70rem;
      margin-top: 4rem;
      max-width: 160rem;
    }

        /* Animation */
        @include animation(ani-heading 0.8s 0.6s cubic-bezier(.49, .01, .55, .99) forwards);
        .site-loaded & {
          @keyframes ani-heading {
            0% {
              @include transform(translateY(1.2rem));
            }
            33% {
              opacity: 1;
            }
            100% {
              opacity: 1;
              @include transform(translateY(0));
            }
          }
            @-webkit-keyframes ani-heading {
              0% {
                @include transform(translateY(1.2rem));
              }
              33% {
                opacity: 1;
              }
              100% {
                opacity: 1;
                @include transform(translateY(0));
              }
            }
        
        }

  }
}