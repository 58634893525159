.quotes-wrapper {
  padding: 1rem 0;
  .quotes-material {
    position: relative;
    z-index: 2;
    @include displayflex;
    flex-flow: column nowrap;
    justify-content: space-between;
      @media(min-width: $tablet) {
        flex-flow: row wrap;
      }
      
    /* Box */
    .box {
      @include flex(0 1 auto);
      background: $blue;
      font-family: $fontheadingbold;
      color: $grey;
      padding: 4rem;
      font-size: 1.2rem;
      @include box-shadow(0, 0, .7rem, rgba(0, 0, 0, .3));
      @include transition(all .23s ease-in-out);

      .quote {
        font-size: 2rem;
        line-height: 1;
        font-family: $fontprimary;
        font-weight: 600;
        @media(min-width: $tablet) {
          font-size: 2.3rem;
        }
      }
      .name {
        color: $green;
      }
      .location {
        text-transform: uppercase;
        margin: 0 0 2rem 0;
      }
      /* img */
      .img-wrapper {
        position: relative;
        margin: 1.5rem 0 3rem 0;        
        .img {
          width: 100%;
          margin: 0 auto;
          height: 17rem;
          width: 17rem;
          @include border-radius(50%);
          // @include flex(0 1 64%);
          background-size: 100%;
          background-position: center;
        }
      }
    }
  }

  /* Box count specifics */

  .quotes-material.count-3 {
    .box {
      &:nth-child(2) {
        margin-top: -2rem;
        margin-bottom: -2rem;
        .img-wrapper {
          .img {
            height: 18rem;
            width: 18rem;
          }
        }
      }
      @media(min-width: $laptop) {
        @include flex(0 auto 33%);
        width: 33%;
      } 
      &:nth-child(1),
      &:nth-child(3) {
        display: none;
        @media(min-width: $laptop) {
          display: block;
        } 
      }
    } 
  }
  .quotes-material.count-2 {
    .box {
      .quote-text-wrapper {
        display: inline-block;
        float: left;
        width: 100%;
      }

      .img-wrapper {
        display: inline-block;
        float: left;
        width: 100%;
        margin: 0 3rem 0 0;
       .img {
          width: 16rem;
          height: 16rem;
        }
      }
      @media(min-width: $laptop) {
        @include flex(0 auto 49%);
        text-align: left;
        width: 49%;
        .quote-text-wrapper {
          width: 60%;
        }
        .img-wrapper {
          width: 30%;
        }
      } 
      &:nth-child(2) {
        display: none;
        @media(min-width: $laptop) {
          display: block;
        } 
      }  
    } 
  }
  .quotes-material.count-1 {
    .box {      
      .quote-text-wrapper {
        display: inline-block;
        float: left;
        width: 100%;
      }
      .img-wrapper {
        display: inline-block;
        float: left;
        width: 100%;
        margin: 0 3rem 0 0;
       .img {
          width: 16rem;
          height: 16rem;
        }
      }
      @media(min-width: $laptop) {
        @include flex(0 auto 100%);
        text-align: left;
        width: 100%;
        .quote-text-wrapper {
          width: 60%;
        }
        .img-wrapper {
          width: 30%;
        }
      }   
    } 
  }

  .bottom-background {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: -.1rem;
    z-index: 1;
  }
}