/* front hero styles
––––––––––––––––––––––––––––––––––––––––––––––––– */

.videoInsert {
  position: absolute; 
  right: 0; 
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  width: auto; 
  height: auto; 
  z-index: -100;
  background-size: cover;
  overflow: hidden;
}

.owl-front-banner {
  display: block;
  width: 100%;
}
