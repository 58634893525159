/*!
Theme Name: Pigment Boilerplate
Theme URI: http://pigment.se/
Author: Pigment AB
Author URI: http://pigment.se/
Version: 1.0
*/

// basic stuff
@import "base/_normalize";
@import "base/_rem_em";
@import "base/_variables";
@import "base/_mixins";
@import "base/_typo";
@import "base/_general";
@import "base/_grid";
@import "base/_forms";
@import "base/_gform";
@import "base/_hubspot";
@import "base/_cookie-bar";

// vendors styles
@import "vendors/_svg-icons";
@import "vendors/_owl";
@import "vendors/_owl-theme";
@import "vendors/_slick";

// header
@import "../../components/header/_header";
@import "../../components/header/menu/_menu";
@import "../../components/header/menu/_mega-menu";
@import "../../components/header/menu/_menu-overlay";
@import "../../components/header/social-icons/_social-icons";
@import "../../components/header/search/_search";
@import "../../components/header/language/_language";

// footer
@import "../../components/footer/_footer";

// banner
@import "../../components/front-banner/_front-banner";
@import "../../components/banner/_banner";

// archive
@import "../../components/archive/_archive";

// single post
@import "../../components/archive/_single-post";

// search
@import "../../components/search/_search";

// flexible
@import "../../components/flexible/box-img-link-image-separated/_box-img-link-image-separated";
@import "../../components/flexible/box-icon-text-link-grid-active/_box-icon-text-link-grid-active";
@import "../../components/flexible/box-icon-text-link-grid-active-2col/_box-icon-text-link-grid-active-2col";
@import "../../components/flexible/banner-block/_banner-block";
@import "../../components/flexible/box-text-link-grid/_box-text-link-grid";
@import "../../components/flexible/box-text-link-striped/_box-text-link-striped";
@import "../../components/flexible/video-text-link/_video-text-link";
@import "../../components/flexible/bullet-list/_bullet-list";
@import "../../components/flexible/list-material/_list-material";
@import "../../components/flexible/text-link/text-link";
@import "../../components/flexible/text-block/text-block";
@import "../../components/flexible/image-block/image-block";
@import "../../components/flexible/quotes/_quotes";
@import "../../components/flexible/clients/_clients";
@import "../../components/flexible/gallery/_gallery";
@import "../../components/flexible/video-gallery/_video-gallery";
@import "../../components/flexible/table/_table";
@import "../../components/flexible/cta/_cta";
@import "../../components/flexible/downloads/_downloads";
@import "../../components/flexible/team/_team";
@import "../../components/flexible/map/_map";
@import "../../components/flexible/modal/_modal";
@import "../../components/flexible/wysiwyg/_wysiwyg";
@import "../../components/flexible/box-img-link-image-reverse/_box-img-link-image-reverse";
@import "../../components/flexible/careers-block/_careers-block";
@import "../../components/flexible/social-feed/_social-feed";
