.box-img-text-separated-wrap {
  position: relative;
  z-index: 2;
  @include displayflex;
	flex-flow: column nowrap;
	justify-content: space-between;
	  @media(min-width: $tablet) {
      flex-flow: row wrap;
      padding: 4rem 0 0 0;
    }
    
  /* Box */
  .box-wrap {
    @include flex(0 1 auto);
    @include transition(all .23s ease-in-out);

		@media(min-width: $tablet) {
      @include flex(0 auto 50%);
      width: 50%;
      margin: 0;
    }

    p {
      padding: 0 2rem;
      @media(min-width: $tablet) {
        padding: 0 4rem;
      }
    }
    .btn {
      margin: 0 0 3rem 0;
      @media(min-width: $tablet) {
        margin: 0 0 6rem 0;
      }
    }
  }
  h2 {
    text-transform: none;
    color: $white;
    font-family: $fontheadingbold;
  }
  /* img */
  .img {
    width: 100%;
    margin: 0 auto;
    // // @include flex(0 1 64%);
    // background-size: 100%;
    // background-position: center;
  }
  .btn {
    background: none;
    color: $green;
  }
}

.bottom-background {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -.1rem;
  z-index: 1;
  @media(min-width: $tablet) {
    height: 65%;
  }
  @media(min-width: $desktop) {
    height: 55%;
  }
}