.archive-wrapper {
  max-width: 120rem;
  margin: 0 auto;
  padding: 3rem 2rem;
  @media (min-width: $tablet) {
    padding: 7rem 0;
  }

  h2 {
    margin: 0 0 1rem 0;
    @media (min-width: $tablet) {
      margin: 0 0 4rem 0;
    }
  }
  .btn {
    z-index: 2;
    margin: 4rem 0 0 0;
  }

  .archive {
    position: relative;
    z-index: 2;
    flex-flow: column nowrap;
    @include displayflex;
    @media (min-width: $tablet) {
      flex-flow: row wrap;
    }
    /* Box */

    .box {
      width: 100%;
      padding: 0;
      text-align: left;
      margin-bottom: 4rem;
      @include flex(0 1 auto);
      @include transition(all 0.23s ease-in-out);

      @media (min-width: $tablet) {
        margin: 0;
        padding: 2.5rem 2.5rem 7.5rem 2.5rem;
      }

      h3 {
        a {
          font-family: $fontheadingregular;
          font-size: 1.9rem;
          position: relative;
          display: inline-block;
          padding: 2rem 0 0 0;
          text-align: left;
          text-transform: uppercase;
          color: $white;
          color: $green;
        }
      }

      .category-name {
        font-size: 1.9rem;
        text-transform: uppercase;
        color: white;
      }

      .text-wrapper {
        width: 100%;
        padding: 3rem 0 0 0;

        p {
          margin-bottom: 1rem;
        }
      }
      /* img */
      .img-wrapper {
        position: relative;
        width: 100%;
        height: 19rem;
        margin: 0 3rem 0 0;
        .img {
          width: 100%;
          height: 19rem;
          margin: 0 auto;
          background-position: center;
          // @include flex(0 1 64%);
          background-size: 100%;
        }

        .img-overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 1;
          background: $gradient;
          @include transition(all 0.23s ease-in-out);
          &:hover {
            opacity: 0;
          }
        }
      }

      .img-empty {
        float: left;
        width: 100%;
        height: 19rem;
        margin: 0 3rem 0 0;
        background: lighten($blue, 8);
        @media (min-width: $tablet) {
          float: left;
          width: 100%;
          margin: 0 3rem 3rem 0;
        }
      }
    }
  }

  /* Filter */
  .searchandfilter {
    display: inline-block;
    width: 100%;
    @media (min-width: $tablet) {
      padding: 0 2.5rem;
      margin-bottom: 4rem;
    }
  }

  .search-filter-result-item {
    @media (min-width: $tablet) {
      width: 33%;
      @include displayflex;
    }
  }

  .searchandfilter label {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  .searchandfilter input[type="text"] {
    display: inline-block;
    width: 100%;
    padding: 1.5rem 1.5rem !important;
    @media(min-width: $tablet) {
      margin-bottom: 4rem;
    }
  }

  .sf-field-search label {
    width: 100%;
  }

  .sf-field-post_type label,
  .sf-field-taxonomy-content label {
    width: 100%;

    @media (min-width: $tablet) {
      float: left;
      width: 49%;
    }
  }
  .sf-field-post_type label {
    @media (min-width: $tablet) {
      margin-right: 1%;
    }
  }
  .sf-field-taxonomy-content label {
    @media (min-width: $tablet) {
      margin-left: 1%;
    }
  }

  .searchandfilter input[type="text"]::-webkit-input-placeholder {
    color: $lightgrey;
  }

  .searchandfilter input[type="text"],
  .searchandfilter select {
    font-size: 1.6rem;
    padding: 1.2rem;
    color: $lightgrey;
    border: 0.1rem solid $lightgrey;
    border-radius: 0;
    background-color: transparent;
  }

  .searchandfilter select {
    background-image: url(assets/images/icons/arrow-down-blue.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .searchandfilter ul li {
    padding: 0;
  }

  .search-filter-scroll-loading {
    margin: 0 auto;
    border: 5px solid $green;
    border-right-color: rgba($green, 0.2);
  }
}
