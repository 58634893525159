// Footer styles
.footer {
  background: $blue;
  border-top: 0.1rem solid $black;
  .footer-wrapper {
    margin: 0 auto;
    padding: 5rem 2.5rem;
    @include displayflex;
    flex-flow: column nowrap;
    justify-content: space-between;
    color: $white;
    text-align: left;
    @media(min-width: $tablet) {
      flex-flow: row nowrap;
      padding: 10rem 2.5rem;
    }
    @media (min-width: $desktop-l) {
      max-width: 150rem;
    }
    .footer-item {
      @include flex(0 1 auto);
      margin-bottom: 2rem;
      h3 {
        font-size: 1.6rem;
        padding-bottom: .7rem;
      }
      @media(min-width: $tablet) {
        @include flex(0 1 24%);
        margin: 0;
      }

      li {
        list-style: none;
        font-size: 1.6rem;
      }

      .search-wrapper {
        display: block;
        position: relative;
        width: 100%;
        z-index: 1;
        margin: 3rem 0 1rem 0;
        top: 0;

        @media(min-width: $tablet) {
          margin: 0;
        }
        #search {
          input[type=search] {
            color: $white;
            margin: 0;
            width: 100%;
            border: 0.1rem solid $green;
          }
        }
      }

      .site-links {
        padding: 3rem 0 0 0;
        text-transform: uppercase;
        font-size: 1.2rem;
        @media(min-width: $tablet) {
          float: right;
          text-align: right;
          padding: 2rem 0 0 0;
        }
      }

      .social-icons {
        position: relative;
        left: auto;
        top: auto;
        display: block;
        li {
          width: auto;
          margin-right: 1rem;
        }
      } 
      &:nth-child(1) {
        width: 50%;
        @media(min-width: $tablet) {
          max-width: 15%;
          width: auto;
        }
      }  
      &:nth-child(2) {
        order: 3;
        @media(min-width: $tablet) {
          order: 2;
          min-width: 30%;
          width: auto;
        }
      }    
      &:nth-child(3) {
        order: 2;
        @media(min-width: $tablet) {
          order: 3;
        }
      }      
      &:nth-child(4) {
        // order: 1;
        right: 3rem;
        width: 100%;
        order: 4;
        // position: absolute;
        @media(min-width: $tablet) {
          position: relative;
          right: auto;
          order: 4;
          width: auto;
        }
      }
    }

  }
}

.footer-bottom {
  max-width: 120rem;
  padding: 0 3rem 4rem 3rem;
  margin: 0 auto;
  background: $blue;
  @include displayflex;
  flex-flow: column nowrap;
  justify-content: space-between;
  @media(min-width: $tablet) {
    flex-flow: row wrap;
    padding: 0 2.5rem 4rem 2.5rem;
  }
  @media (min-width: $desktop) {
    max-width: 150rem;
  }
  .footer-copyright {
    font-size: 1.2rem;
    text-align: center;
    order: 1;
    @media (min-width: $tablet) {
      text-align: left;
      order: 2;
    }
    p {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0 3rem;
      @media (min-width: $tablet) {
        padding: 0;
      }
    }
    a {
      display: inline-block;
      text-transform: uppercase;
      color: $lightblue;
      margin: 1rem 0;
      @media(min-width: $tablet) {
        margin: 0;
      }
    }
    @media(min-width: $tablet) {
      @include flex(0 1 48%);
      width: 48%;
    }
  } 
  .footer-logo {
    img {
      max-width: 10rem;
    }
    @media(min-width: $tablet) {
      order: 2;
      @include flex(0 1 48%);
      width: 48%;
      img {
        float: right;
      }
    }
  } 

  &:before {
    content: '';
    width: 100%;
    height: .1rem;
    margin: 0 0 2rem 0;
    background: $green;
    background: -moz-linear-gradient(90deg, rgba(92,172,113,1) 0%, rgba(92,172,113,1) 68%, rgba(2,81,51,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(92,172,113,1) 0%, rgba(92,172,113,1) 68%, rgba(2,81,51,1) 100%);
    background: linear-gradient(90deg, rgba(92,172,113,1) 0%, rgba(92,172,113,1) 68%, rgba(2,81,51,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5cac71",endColorstr="#025133",GradientType=1);
  }
}