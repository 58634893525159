.career-block {
  padding: 4rem 2rem;
  text-align: left;
  
  @media(min-width: $tablet) {
    padding: 6rem 0;
  }

  &__title {
    margin-bottom: 2rem;
    
    @media (min-width: $tablet) {
      font-size: 3.6rem;
    }
  }

  &__content {
    text-align: left;
  }

  &__listing {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    width: 100%;
    background: $green;
    color: $white;
    padding: 1.5rem 2rem;

    &--title a {
      color: $white;
      font-size: 2.2rem;

      &:hover {
        opacity: 0.8;
      }
    }

    .btn {
      background: $white;
      color: $green;

      &:hover {
        opacity: 0.9;
      }
    }

    &--details {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--location {
      font-style: normal;

      &:not(:empty) {
        padding-bottom: 1rem;
      }
    }

    &::marker {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
