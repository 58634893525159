//Language
.language-toggle {
    display: block;
    position: absolute;
    z-index: 333;
    top: 2.5rem;
    right: 7rem;
  @media (min-width: $laptop) {
    top: .75rem;
    left: 1rem;
    margin: 0 1.75rem 0 0;
    position: relative;
    display: inline-block;
    float: left;
  }

  .language-icon {
    cursor: pointer;
    width: 2.1rem;
    height: 2.1rem;
    background-image: url(assets/images/icons/language.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.8rem 1.8rem;
    @media (min-width: $laptop) {
      background-size: 1.6rem 1.6rem;
    }
  }

  select {
    width: 20rem;
    height: 4rem;
    padding: 0 1rem;
  }

  .language-content {
    background: $green;
    left: -1.6rem;
    top: 3.8rem;
    position: absolute;
    display: none;
    padding: 2rem;
    @media (min-width: $laptop) {
      left: auto;
      right: -1.6rem;
    }
    &:before {
      position: absolute;
      margin-top: -3rem;
      margin-left: -.7rem;
      display: block;
      width: 0;
      height: 0;
      content: '';
      border-right: 1.4rem solid transparent;
      border-bottom: 1.4rem solid $green;
      border-left: 1.4rem solid transparent;
      opacity: 1;
      @include transition(all .5s ease);
      @media (min-width: $laptop) {
        margin-left: 18rem;
      }
    }
    &.active {
      display: block;
    }
  }

}