.box-icon-text-link-grid-2col {
  &.less-margin {
    .box-text-link-grid-wrap {
      padding: 2rem 0 7rem 0;

      @media (min-width: $tablet) {
        padding: 2rem 0 7rem 0;
        flex-flow: row wrap;
        align-items: flex-start;
      }
    }
  }

  .box-text-link-grid-wrap {
    position: relative;
    z-index: 2;
    padding: 4rem 2rem;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    @include displayflex;

    @media (min-width: $tablet) {
      padding: 7rem 0;
      flex-flow: row wrap;
      max-width: 100rem;
    }


    h2 {
      width: 100%;
      margin: 0 0 4rem 0;
    }

    /* Box */
    .box-wrap {
      z-index: 3;
      margin: 0 0 3rem 0;
      padding: 2rem 0;
      border-bottom: 0.1rem solid $lightgrey;
      text-align: left;
      width: 100%;
      @include flex(0 1 auto);
      @include transition(all 0.23s ease-in-out);

      @media (min-width: $tablet) {
        align-self: stretch;
        display: flex;
        margin: 0;
        border-right: 0.1rem solid $lightgrey;
        border-bottom: none;
        width: 50%;
        margin: 0;
        padding: 0 2rem;
        @include flex(0 auto 50%);
      }

      &:last-of-type {
        border-bottom: none;
        
        @media (min-width: $tablet) {
          padding: 0 4rem;
          border-right: none;
        }
      }

      .col {
        width: 100%;
        @media (min-width: $tablet) {
          width: 40%;
        }
        img {
          float: left;
        }
      }
      .col-last {
        width: 100%;
        @media (min-width: $tablet) {
          width: 60%;
        }
      }

      .box-icon-wrapper {
        display: inline-block;

        .box-icon {
          margin: 0 0 3rem 0;
        }
      }

      .ingress {
        text-align: left;
        opacity: 0.6;
        color: $white;
      }
    }

    .btn {
      padding: 2rem 0 2rem 0;
      color: $green;
      background: none;
      @media (min-width: $tablet) {
        padding: 2rem 0 0 0;
      }
    }

    h3 {
      font-family: $fontheadingregular;
      font-size: 1.9rem;
      color: $white;

      @media (min-width: $tablet) {
        font-size: 2rem;
      }
    }

    .text {
      font-family: $fontheadingbold;
      font-size: 1.8rem;
      line-height: 1;
      margin: 1rem 0 2rem 0;
      color: $green;

      @media (min-width: $tablet) {
        font-size: 2rem;
      }
    }
  }
}
