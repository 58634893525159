//box-shadow Use: @include box-shadow(0, 0, 7px, rgba(0, 0, 0, .3));
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
  -webkit-box-shadow: $top $left $blur $color #{$inset};
  -moz-box-shadow: $top $left $blur $color #{$inset};
  box-shadow: $top $left $blur $color #{$inset};
}

// border-radius Use; @include border-radius(10px); }
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// Transition Use; @include transition(all .3s ease); }
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// Transform Use; 
@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

// Set aspect ratio 
@mixin aspect-ratio($width, $height) {
  padding-top: ($height / $width) * 100%;
}
// use class like this
// .bg-img {
// @include aspect-ratio(16, 9);
// }

// Flexbox. @include displayflex;
@mixin displayflex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Flexbox. @include displayinlineflex;
@mixin displayinlineflex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// animation Use: @include animation(btn-case-ani 8s cubic-bezier(.49, .01, .55, .99) 3s infinite);
@mixin animation($args...) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -o-animation: $args;
  -ms-animation: $args;
  animation: $args;
}

// Flex shorthand values. @include flex(0 1 auto);
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex order. @include order(1);
@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
  -moz-box-ordinal-group: $val;     
  -ms-flex-order: $val;     
  -webkit-order: $val;  
  order: $val;
}

// Flex align self. @include alignself(center);
@mixin alignself($val) {
  -ms-flex-align-self: $val;
  -webkit-align-self: $val;
  align-self: $val;
}

// Box sizing @include box-sizing;
@mixin box-sizing {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

@mixin black-and-gray {
  -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(100%); /* FF 35+ */
}
@mixin black-and-gray-to-color {
  -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(0%); /* FF 35+ */
}