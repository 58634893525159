.downloads{
  background: $grey;
  .ingress {
    max-width: 70rem;
    margin: 0 auto;
  }
  .downloads-wrap {
    position: relative;
    z-index: 2;
    padding: 6rem 0;
    align-items: center;
    justify-content: center;
    color: $black;
    @include displayflex;
    flex-flow: column nowrap;

    @media(min-width: $tablet) {
      padding: 10rem 0;
      flex-flow: row wrap;
    }

    /* Box */
    .box-wrap {
      z-index: 3;
      @include flex(0 1 auto);

      @media(min-width: $tablet) {
        @include flex(0 auto 100%);
        width: 100%;
        margin: 0;
      }

      .box {
        margin: 0 0 2rem 0;
        a {
          font-size: 1.4rem;
          color: $black;
          font-family: $fontheadingbold;
          text-transform: uppercase;

          &:before {
            content: '';
            position: relative;
            display: inline-block;
            width: 1.3rem;
            height: 1.3rem;
            margin: 0 1rem 0 0;
            background-image: url(assets/images/icons/download.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 1.3rem 1.3rem;
          }
        }
      }
    }

    .btn {
      background: none;
      color: $green;
    }

    h2 {
      font-family: $fontheadingbold;
      font-size: 2.5rem;
      color: $black;
      margin: 0 0 2rem 0;

      @media(min-width: $tablet) {
        font-size: 4.5rem;
      }
    }
  }
}