.box-link-text-striped {
  .box-link-text-striped-wrap {
    position: relative;
    z-index: 2;
    padding: 4rem 2rem;
    text-align: left;
    flex-flow: column nowrap;
    justify-content: space-between;
    @include displayflex;
    @media (min-width: $tablet) {
      padding: 0;
      align-items: center;
      flex-flow: row wrap;
    }

    /* Box */
    .box-wrap {
      z-index: 3;
      @include flex(0 1 auto);
      @include transition(all 0.23s ease-in-out);

      @media (min-width: $tablet) {
        width: 50%;
        @include flex(0 auto 50%);
      }

      &__content {
        @media (min-width: $tablet) {
          max-width: 40rem;
          margin: 0 auto;
        }
      }

      &.striped-col {
        background-color: transparent;
        background-image: url(assets/images/icons/stripes-long.svg);
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 100%;
        margin-bottom: 2rem;

        @media (min-width: $tablet) {
          margin-bottom: 0;
          background-position: center;
          text-align: center;
        }

        .box-link-text-striped-title {
          padding: 4rem 0;
          @media (min-width: $tablet) {
            padding: 13rem 0;
            font-size: 5rem;
          }
        }
      }
    }
  }
}
