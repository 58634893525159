/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.gform_wrapper {

  .gfield {
    position: relative;

    @media (min-width: $tablet) {
      width: 50%;
      display: inline-block;
    }

    input, select {
      width: 100%!important;
    }

    select {
      padding: 2px 0 2px 2rem!important;
      background-image: url(assets/images/icons/arrow-down-blue.svg);
      background-repeat: no-repeat;
      background-position: 97% 50%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    .gfield_label {
      font-weight: normal;
    }

    input[type="text"] label {
      position: absolute;
      top: -2.5rem;
    }

    &.form-field-full {
      width: 100%;
    }

    .gfield_required {
      color: $lightgrey;
    }
  }
}