.clients {
  padding: 0 1.5rem;
  
  &.black {
    background: $black;
  }
  
  &__wrapper {
    padding: 3rem 0;
    max-width: 120rem;
    margin: 0 auto;
    
    .heading {
      font-size: 1.6rem;
      color: $green;
      margin: 0 0 3rem 0;
      @media(min-width: $tablet) {
        font-size: 1.8rem;
      }
    } 

    .green-heading {
      .secondary & {
        color: $white;
      }
    }
  }

  &__rows {
    margin: 2rem 0 0 0;

    .owl-stage {
      white-space: nowrap;
      min-height: 70px;

      .owl-item {
        display: inline-block;
        vertical-align: middle;
        float: none;
      }
    }

    .owl-nav .owl-prev, 
    .owl-nav .owl-next {
      .secondary & {
        filter: brightness(6);
      }
    }
  }

  /* Box */
  &__box {
  width: 100%;

  @media(min-width: $tablet) {
    padding: 0 3rem;
    width: auto;
  }
      
    /* img */
    .client__img {
      cursor: pointer;
      width: 10rem!important;
      margin: 1rem auto;
      @include transition(all .23s ease-in-out);
      @include black-and-gray;
      &:hover {
        @include black-and-gray-to-color;
      }
    }
  }

  .owl-prev {
    margin: -.5rem 0 0 0!important;
  }
  .owl-next {
    margin: -.5rem 0 0 0!important;
  }
}