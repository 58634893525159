.post-single {
  .smaller-width {
    max-width: 80rem;
  }

  .post-item {
    max-width: 90rem;
    padding: 3rem 2rem;
    text-align: left;
    @media (min-width: $tablet) {
      padding: 10rem 0;
    }
  }

  h2 {
    padding: 0 0 2rem 0;
  }
  .date {
    margin: 0 0 2rem 0;
    color: $blue;
  }

  p {
    img {
      float: left;
      margin: 2rem 0;
      @media (min-width: $tablet) {
        margin: 0 2rem 2rem 0;
      }
      @media (max-width: $tablet) {
        width: 100%;
      }
    }
  }
  figure {
    float: left;
    margin: 2rem 0;
    width: auto!important;
    @media (min-width: $tablet) {
      margin: 0 2rem 2rem 0;
    }
    @media (max-width: $tablet) {
      width: 100%!important;
      img {
        width: 100%!important;
      }
    }
  }

  figcaption {
    font-size: 1.3rem;
  }

  .meta {
    .name {
      span {
        color: $green;
      }
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: .1rem;
      margin: 0 0 2rem 0;
      background: $green;
      background: -moz-linear-gradient(90deg, rgba(92,172,113,1) 0%, rgba(92,172,113,1) 68%, rgba(2,81,51,1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(92,172,113,1) 0%, rgba(92,172,113,1) 68%, rgba(2,81,51,1) 100%);
      background: linear-gradient(90deg, rgba(92,172,113,1) 0%, rgba(92,172,113,1) 68%, rgba(2,81,51,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5cac71",endColorstr="#025133",GradientType=1);
    }
  }

  .single-article__sharing-icon {
    display: inline-block;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    opacity: 0.6;

    .icon-facebook,
    .icon-twitter,
    .icon-email,
    .icon-linkedin {
      display: inline-block;
      width: 3.5rem;
      height: 3.5rem;
      margin-right: .7rem;
      margin-bottom: -0.4rem;
      background-repeat: no-repeat;
      background-position: center;
      object-fit: cover;
    }
    .icon-facebook {
      background-image: url(assets/images/icons/facebook.svg);
    }
    .icon-linkedin {
      background-image: url(assets/images/icons/linkin.svg);
    }
    .icon-email {
      background-image: url(assets/images/icons/email.svg);
    }
    .icon-twitter {
      background-image: url(assets/images/icons/twitter.svg);
    }
  }
  
}