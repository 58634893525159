/* custom hubspot styling
––––––––––––––––––––––––––––––––––––––––– */

.hs-form {
  //Fields
  label {
    display: inline-block;
  }

  .hs-input {
    width: 100% !important;
    color: $lightgrey;
    background: lighten($black, 1.5);
  }

  select {
    height: auto;
    color: $lightgrey;
  }

  fieldset {
    max-width: 100% !important;
  }

  // Error messages
  .hs-error-msgs {
    list-style: none;
  }

  .hs-error-msg,
  .hs-form-required {
    font-size: 1.2rem;
    margin-top: -0.5rem;
    color: red;
  }

  // Text
  .hs-richtext {
    font-size: 1.4rem;
    width: 100%;
  }

  // Consent
  .hs-form-booleancheckbox-display input {
    width: auto !important;
  }

  .hs-form-booleancheckbox {
    list-style: none;

    input[type="checkbox"] {
      margin-top: 0.5rem;
    }
  }

  // Button
  .hs-button {
    margin-top: 3rem;
    color: $white;
    background-color: $green;
  }
}
