/* custom grid
––––––––––––––––––––––––––––––––––––––––– */
.container {
  box-sizing: border-box;
  margin: 0 auto;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	width: 100%;
	max-width: 160rem;
	@media (min-width: $tablet) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
  }
  
}
.wrapper {
  display: block;
  max-width: 120rem;
  margin: 0 auto;

  @media (min-width: $desktop-l) {
    max-width: 150rem;
  }
}