// Two columns
.video-text-link-wrap {
  padding: 3rem 2rem;
  overflow: hidden;
  flex-flow: column nowrap;
  align-items: center;
  @include displayflex;

  @media(min-width: $tablet) {
    padding: 7rem 2rem;
    flex-flow: row nowrap;
  }

  &:hover {
    .left-wrap {
      .overlay-class {
        @include transform(scale(1.03));
      }
    }
  }

  .right-wrap,
  .left-wrap {
    overflow: hidden;
    align-items: center;
    @include displayflex;
    @include flex(0 1 auto);

    @media(min-width: $tablet) {
      margin: 0;
      @include flex(0 1 50%);
    }
  }

  .left-wrap {
    position: relative;
    display: block;
    align-self: auto;
    width: 100%;
    padding: 0.5rem;
    
    @media(min-width: $tablet) {
      padding: 2rem;
      padding: 0;
      max-height: auto;
    }

    .embed-container {
      height: 100%;
    }

    .overlay-class {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background: $gradient;
  
      @include transition(all .63s ease-in-out);
      &:before {
        position: absolute;
        content: '';
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $gradient;
        @include transform(scale(1.03));
      }
    }

    .play-video {
      position: absolute;
      z-index: 4;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: 5rem;
      height: 5rem;
      margin: -2.5rem;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 5rem 5rem;

    }
  }

  .right-wrap {
    padding: 2rem 0.5rem 0.5rem 0.5rem;
    text-align: left;
    align-self: stretch;
    // display: inline-block;
    flex-shrink: 1;
    @media(min-width: $tablet) {
      margin: 0;
      padding: 4rem;
    }

    .right-wrap-content {
      display: block;
      margin-right: 0%;
      width: 100%;
      @media(min-width: $tablet) {
        margin-right: 20%;
      }

      h2 {
        margin-top: 2rem;
      }
    }
  }
  // Responsive mp4-video
  video {
    width: 100% !important;
    height: auto !important;
  }

  .video-pdf-list {
    width: 100%;
  }


  .text-list {
    color: $lightgrey;
    padding: 1rem 0;
    text-transform: uppercase;

    &:before {
      content: '';
      background-image: url(assets/images/icons/play-small.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border-radius: 0;
      display: inline-block;
      width: 3rem;
      height: 3rem;
      margin-bottom: -1rem;
      margin-right: 1rem;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }



  .slider-wrapper {
    max-width: 85rem;
    margin: 0 auto;
    
    .gallery {
      position: relative;
      // overflow: hidden;
      width: 100%;
      height: auto;
      z-index: 1;
    }
    .slick-track {
      margin: 0;
    }

    .gallery-pagination {
      position: relative;
      margin-bottom: 0;
      padding-top: 1rem;

      @media (min-width: $desktop-m) {
        padding-top: 2rem;
      }
      
      .play-slide-video {
        position: relative;
        z-index: 4;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: 3rem;
        height: 3rem;
        margin: -2.5rem;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 3rem 3rem;
      }

      .text-list {
        display: block;
        width: 100%;
      }

      .slide {
        cursor: pointer;
        outline: none;

        img {
          padding: 0 .8rem 0 0;
          opacity: 0.6;
          @include transition(all .3s ease);

          &:hover {
            opacity: 0.9;
          }
        }

        &.slick-current {
          img {
            opacity: 1;
          }
        }
      }

      .slide-arrow {
        font-size: 2rem;
        line-height: 32px;
        position: absolute;
        z-index: 22;
        top: 50%;
        width: 3.2rem;
        height: 3.2rem;
        transform: translateY(-50%);
        text-align: center;
        letter-spacing: 0;

        &:before {
          display: none;
        }

        &:hover {
          background: lighten($blue, 15);
        }
      }

      .preview-prev {
        left: 0;

        &:after {
          position: absolute;
          bottom: .9rem;
          left: 1.2rem;
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          content: '';
          transform: rotate(-135deg);
          vertical-align: top;
          color: $green;
          border-width: .2rem .2rem 0 0;
          border-style: solid;
        }
      }

      .preview-next {
        right: 0;

        &:after {
          position: absolute;
          right: 1.2rem;
          bottom: .9rem;
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          content: '';
          transform: rotate(45deg);
          vertical-align: top;
          color: $green;
          border-width: .2rem .2rem 0 0;
          border-style: solid;
        }
      }
    }

    .preview-pagination-slider {
      font-size: 0;
      line-height: 0;
      position: relative;
      // overflow: hidden;
      margin: 0;
      letter-spacing: -.4rem;
      width: 100%;
      text-align: left;

      .slide {
        width: 15%;
        float: left;

        img {
          width: 100%;
          float: left;
        }
      }

      .slick-list {
        overflow: hidden;
      }
    }

  }

  .overlay-class {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background: $gradient;
    @include transition(all .63s ease-in-out);
    &:before {
      position: absolute;
      content: '';
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $gradient;
      @include transform(scale(1.03));
    }
  }

  .play-slide-video {
    position: absolute;
    z-index: 4;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 6rem;
    height: 6rem;
    margin: -2.5rem;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6rem 6rem;

  }
}
