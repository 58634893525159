.banner-block {
  &__wrap {
    position: relative;
    z-index: 2;
    padding: 4rem 2rem;
    text-align: left;
    @media (min-width: $tablet) {
      padding: 15rem 2rem;
    }
  }

  .container {
    padding: 0;
  }

  /* Box */
  &__box-wrap {
    z-index: 3;
    @include transition(all 0.23s ease-in-out);

    @media (min-width: $tablet) {
      max-width: 40rem;
      width: 100%;
    }
  }
  
  .btn {
    color: $green;
    background: none;
  }

  .btn-arrow {
    margin-bottom: 0.3rem;
  }

  /* img */
  .img {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // opacity: .4;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
  }
}
