.box-text-link-grid {

  .box-text-link-grid-wrap {
    position: relative;
    z-index: 2;
    padding: 5rem 2rem;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    @include displayflex;

    @media(min-width: $tablet) {
      padding: 10rem 0;
      flex-flow: row wrap;
    }

    h2 {
      width: 100%;
      margin: 0 0 4rem 0;
    }

    /* Box */
    .box-wrap {
      z-index: 3;
      margin: 0 0 3rem 0;
      padding: 0 0 2rem 0;
      text-align: left;
      border-bottom: .1rem solid $green;
      align-self: stretch;
      @include flex(0 1 auto);
      @include transition(all .23s ease-in-out);
      @media(min-width: $tablet) {
        margin: 0;
        padding: 0 2rem;
        border-right: .1rem solid $green;
        border-bottom: none;
      }

      @media(min-width: $tablet) {
        width: 25%;
        margin: 0;
        @include flex(0 auto 25%);
      }

      &:last-of-type {
        border-bottom: none;
        margin:  0;
        padding: 0;
        @media(min-width: $tablet) {
          padding: 0 2rem;
          border-right: none;
        }
      }
    }

    .btn {
      padding: 1.5rem 3.5rem 0 0;
      color: $green;
      background: none;
    }

    h3 {
      font-family: $fontheadingregular;
      font-size: 1.5rem;
      color: $green;

      @media(min-width: $tablet) {
        font-size: 1.7rem;
      }
    }

    .text {
      font-family: $fontheadingbold;
      font-size: 1.8rem;
      line-height: 1;
      margin: 2rem 0 2rem 0;
      color: $green;

      @media(min-width: $tablet) {
        font-size: 2rem;
      }
    }

    .anchor-link {
      display: inline-block;
      margin: 2rem 0 2rem 0;
      @media(min-width: $tablet) {
        margin: 2rem 0 0 0;
      }
    }
  }
}