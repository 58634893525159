
// Set your own varibles if needed
// ------------------------------------------------------

// Colors
$black: #000000;
$darkgrey: #222;
$white: #fff;
$lightgrey: #9B9A9D;
$blue: #333740;
$grey: #D7D8D6;
$green: #5CAC71;
$lightblue: #85878A;
$opacityBlack: rgba(0, 0, 0, 0.9); 
$gradient: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0.1),
  rgba(0, 0, 0, 0.5)
);

// Breakpoints
$mobile: em(550px);
$tablet: em(768px);
$laptop: em(910px);
$desktop: em(1140px);
$desktop-m: em(1350px);
$desktop-l: em(1480px);
$desktop-xl: em(1950px);
$desktop-xxl: em(2300px);

// Fonts
$fontprimary: 'Montserrat', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontheading: 'Replica-Heavy', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontheadingregular: 'Replica-Regular', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontheadingbold: 'Replica-Bold', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;