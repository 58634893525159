// Mega menu
.menu-wrapper {

  // Mega menu styles
  .main-menu .mega-menu {

    // Sub menu
    .sub-menu-wrapper {
      position: absolute;
      top: 4.6rem;
      right: 0;
      visibility: hidden;
      overflow: hidden;
      width: 100%;
      max-width: 90rem;
      margin: 0 auto;
      padding: 4rem;
      text-align: left;
      opacity: 0;
      background: $blue;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
      justify-content: center;
      flex-flow: row nowrap;
      @include displayflex;

      .secondLevel {
        font-family: $fontprimary;
        font-size: 1.7rem;
        line-height: 1.3;
        color: $white;
        z-index: 2;
        margin: 0;
        padding: 0 4rem;
        border-right: 0.1rem solid #ccc;
        @include flex(0 1 25%);
        &:last-child {
          border-right: none;
        }

        a {
          display: block;
          padding: 0 0 2rem 0;
          color: $white;
          &:hover {
            color: $green;
          }
        }
      }

      .sub-menu {
        margin: 0;
        padding: 0;
        li {
          font-family: $fontprimary;
          font-size: 1.3rem;
          display: block;
          padding: 0;
          text-align: left;
          text-transform: uppercase;

          a {
            padding: 0;
          }
        }
      }

      #menu-image-left {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 20rem;
        height: 18rem;
        list-style: none;
        opacity: 0.25;
        border: none;
        background-repeat: no-repeat;
      }

      #menu-image-right {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 30rem;
        height: 15rem;
        list-style: none;
        opacity: 0.25;
        border: none;
        background-repeat: no-repeat;
      }
    }
  }

  // Menu styles
  .main-menu {
    
    // Sub menu
    .sub-menu-wrapper {
      position: absolute;
      display: block;
      visibility: hidden;
      overflow: hidden;
      margin: 0 auto;
      padding: 2rem;
      text-align: left;
      opacity: 0;
      top: 7rem;
      background: $blue;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

      .secondLevel {
        font-family: $fontprimary;
        z-index: 2;
        display: block;
        margin: 0;
        padding: 1rem;

        a {
          display: block;
          color: $white;

          &:hover {
            color: $green;
          }
        }
      }

      .sub-menu {
        margin: 0;
        padding: 0;
        li {
          font-family: $fontprimary;
          font-size: 1.3rem;
          padding: 0;
          text-align: left;
          text-transform: none;
          display: block;
          
          a {
            padding: 0;
          }
        }
      }
    }
  }
}
//end menu styles
