.box-icon-text-link-grid {
  &.less-margin {
    .box-text-link-grid-wrap {
      padding: 2rem 0 7rem 0;

      @media (min-width: $tablet) {
        padding: 2rem 0 7rem 0;
        flex-flow: row wrap;
        align-items: flex-start;
      }
    }
  }

  .box-text-link-grid-wrap {
    position: relative;
    z-index: 2;
    padding: 8rem 2rem;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    @include displayflex;

    @media (min-width: $tablet) {
      padding: 10rem 0;
      flex-flow: row wrap;
    }

    h2 {
      width: 100%;
      margin: 0 0 4rem 0;
    }

    /* Box */
    .box-wrap {
      z-index: 3;
      margin: 0 0 3rem 0;
      padding: 0 0 3rem 0;
      text-align: left;
      border-bottom: 0.1rem solid $lightgrey;
      align-self: stretch;
      @include flex(0 1 auto);
      @include transition(all 0.23s ease-in-out);
      @media (min-width: $tablet) {
        margin: 0;
        padding: 0 4rem 0 4rem;
        border-right: 0.1rem solid $lightgrey;
        border-bottom: none;
      }

      @media (min-width: $tablet) {
        width: 25%;
        margin: 0;
        @include flex(0 auto 25%);
      }

      &:last-of-type {
        border-bottom: none;

        @media (min-width: $tablet) {
          border-right: none;
        }
      }
      .box-icon-wrapper {
        width: 4rem;
      }

      .ingress {
        text-align: left;
        opacity: 0.6;
        color: $white;
      }
    }

    .btn {
      padding: 2rem 0 2rem 0;
      color: $green;
      background: none;
      @media (min-width: $tablet) {
        padding: 2rem 0 0 0;
      }
    }

    h3 {
      font-family: $fontheadingregular;
      font-size: 1.5rem;
      margin: 2.5rem 0 1rem 0;
      color: $white;

      @media (min-width: $tablet) {
        font-size: 1.7rem;
      }
    }

    .text {
      font-family: $fontheadingbold;
      font-size: 1.8rem;
      line-height: 1;
      margin: 1rem 0 2rem 0;
      color: $green;

      @media (min-width: $tablet) {
        font-size: 2rem;
      }
    }
  }
}
