/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */

input,
textarea,
select,
fieldset {
  margin-bottom: 1.5rem;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  font-size: 1.6rem;
  box-sizing: border-box;
  width: 100%;
  padding: 1.85rem 2rem;
  color: $lightgrey;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.2rem solid $blue;
  background: none;
  box-shadow: none;
  outline: none;
}
select {
  height: 5.5rem;
  color: $lightgrey;
  border-top: 0.2rem solid $blue;
  border-left: 0.2rem solid $blue;
  border-right: 0.2rem solid $blue;
  border-bottom: 0.2rem solid $blue;
  background-size: 1.7rem 1.7rem;
  background-position: 93% 50%;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @media (min-width: $tablet) {
    background-position: 98% 50%;
	}
}

label {
  font-weight: normal;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $black;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $black;
}


/* Removes awkward default styles on some inputs for iOS 
–––––––––––––––––––––––––––––––––––––––––––––––––– */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  min-height: 8.5rem;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: 0.1rem solid $white;
  outline: 0;
}

label,
legend {
  display: block;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

fieldset {
  padding: 0;
  border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

label>.label-body {
  font-weight: normal;
  display: inline-block;
  margin-left: 0.5rem;
}


/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.btn,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  font-family: $fontheadingregular;
  font-size: 1.6rem;
  display: inline-block;
  min-width: 10rem;
  letter-spacing: 0.05rem;
  padding: 1.5rem 3.5rem 1.5rem;
  text-align: center;
  color: $white;
  border: none;
  background-color: $green;
  text-decoration: none;
  text-transform: uppercase;
  @include transition(all .3s ease);
}

.btn:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.btn:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  outline: 0;
  background: lighten( $green, 8);
}

.btn.btn-primary,
button.btn-primary,
input[type="submit"].btn-primary,
input[type="reset"].btn-primary,
input[type="button"].btn-primary {
  color: #FFF;
  border-color: $white;
  background-color: $green;
}

.btn.btn-primary:hover,
button.btn-primary:hover,
input[type="submit"].btn-primary:hover,
input[type="reset"].btn-primary:hover,
input[type="button"].btn-primary:hover,
.btn.btn-primary:focus,
button.btn-primary:focus,
input[type="submit"].btn-primary:focus,
input[type="reset"].btn-primary:focus,
input[type="button"].btn-primary:focus {
  color: $white;
  border-color: $white;
  background-color: $green;
}

/* Arrow
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.btn-arrow {
  display: inline-block;
  width: 1.1rem;
  height: .9rem;
  margin-left: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.1rem 1.1rem;
  @include transition(margin-left .83s ease-in-out);
}
.btn-arrow {
  background-image: url(assets/images/icons/arrow.svg);
}

/* Checkbox
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.checkbox-wrap {
  font-size: 1.4rem;
  position: relative;
  top: 0;
  left: 3rem;
  display: block;
  cursor: default;
  pointer-events: initial;

  input {
    position: absolute;
    width: auto;
    opacity: 0;
    appearance: checkbox;
  }

  label {
    color: $black;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: -2.8rem;
    width: 2rem;
    height: 2rem; 
    border: .1rem solid $white;
    background-color: white;
    border-radius: .1rem;
    cursor: default;

    &:after {
      position: absolute;
      top: 0.3rem;
      left: 0.6rem;
      display: none;
      width: 0.7rem;
      height: 1rem;
      content: "";
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border: solid $green;
      border-width: 0 .1rem .1rem 0;
    }
  }

  input:checked~.checkmark {
    background-color: white;
  }

  input:checked~.checkmark:after {
    display: block;
  }
}