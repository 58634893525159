//Social menu
.social-icons-wrapper {

  .menu-active {
    .social-icons {
      display: block;
      position: absolute;
      bottom: 0;
      @media (min-width: $laptop) {
        display: none;
      }
    }
  }

  .social-icons {
    position: absolute;
    display: none;
    bottom: 0;
    left: 1rem;
    margin-right: 5.5rem;

    @media (min-width: $laptop) {
      display: block;
      top: 1.3rem;
    }


    li{
      display: inline-block;
      text-align: left;
      width: 3rem;
      height: 3rem;
      color: $green;
      * {
        color: $green;
      }
      a {
        color: $green;
      }
    }
    .icon {
      display: block;
      margin: 1.05rem auto;
      color: $green;
    }
  }
}