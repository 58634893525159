// Header styles
header {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  height: 7rem;
  text-align: left;
  background: $blue;
  @include transition(all 0.23s ease-in-out);

  .logo {
    position: relative;
    top: 2.3rem;
    left: 2.5rem;
    float: left;
    width: 10rem;
    height: auto;
    @include transition(height 0.23s ease-in-out);
  }

  .dropdown {
    position: relative;
    z-index: 10;
    right: 0;
    display: none;
    margin-top: 5rem;
    padding: 1.5rem 2rem 1.2rem 2rem;
    opacity: 0;
    background: rgba(0, 0, 0, 0.35);
    @include transition(opacity 0.93s ease-in-out);

    li {
      list-style: none;
    }

    &.showMe {
      display: block;
      opacity: 1;
    }
  }

  .search-lang-wrapper {
    background: $blue;
    @media (min-width: $laptop) {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      left: auto;
    }
  }
}

.header-puscher {
  display: block;
  width: 100%;
  padding-top: 7rem;
}

// Navigation wrapper
.menu-wrapper {
  height: 4rem;
  @media (min-width: $laptop) {
    height: 0;
  }
  ul {
    li {
      ul {
        display: none;
      }
    }
  }

  // Menu styles
  .main-menu {
    font-size: 1.4rem;
    display: none;
    margin: 0 5rem 0 3rem;
    @media (min-width: $laptop) {
      display: inline-block;
      float: right;
    }
    .active {
      a {
        color: $green;
      }
    }

    li {
      font-family: $fontheadingregular;
      display: inline-block;
      margin: 0;
      padding: 1.3rem 1.5rem;
      text-transform: uppercase;

      @media (min-width: $laptop) {
        padding: 2.4rem 1.2rem;
      }

      a {
        text-decoration: none;
        color: $white;
        @include transition(all 0.3s ease);
      }

      &:hover {
        a {
          color: $green;
        }
        ul {
          li {
            ul {
              display: block;
            }
          }
        }
        .sub-menu-wrapper {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .menu-item-has-children {
      .secondLevel {
        &:hover {
          &:after {
            display: none;
          }
        }
      }
      &:hover {
        &:after {
          opacity: 1;
          @include transition(all 0.3s ease);
        }
      }
    }
  }
  //end menu styles
}
//end navgition wrapper
