.list-material-wrapper {
  padding: 6rem 0.5rem;
  @media(min-width: $tablet) {
    padding: 10rem 0;
  }

  .wrapper {
    padding: 0 2rem;
  }

  h2 {
    @media(min-width: $tablet) {
      margin: 0 0 4rem 0;
    }
  }
  .btn {
    margin: 4rem 1rem 0 1rem;
    z-index: 2;
    float: left;
  }
  .material-wrap {
    width: 100%
  }
  .horizontal {
    @media(min-width: $tablet) {
      display: flex;
    }

    .box-heading {
      text-align: left;
      font-size: 4.5rem;
      @media(min-width: $tablet) {
        @include flex(0 auto 25%);
        width: 25%;
      }
    }

    .list-material {
      .box {
          @media(min-width: $tablet) {
            @include flex(0 auto 33.33%);
            width: 33.33%;
            margin: 0;
            padding: 1rem;
          }
      }
    }
  }
  
  .vertical {
    max-width: 100rem;
    margin: 0 auto;
    .list-material {
      .box {
          @media(min-width: $tablet) {
            @include flex(0 auto 100%);
            width: 100%;
            margin: 0;
            padding: 3rem;
          }
        .img-wrapper {
          width: 100%;
          float: left;
          margin: 0 3rem 0 0;
          @media(min-width: $tablet) {
            width: 40%;
            float: left;
            margin: 0 3rem 0 0;
          }
        }
        .img-empty {
          height: 24rem;
          width: 100%;
          float: left;
          margin: 0 3rem 0 0;
          background: lighten( $blue, 8);
          @media(min-width: $tablet) {
            width: 40%;
            float: left;
            margin: 0 3rem 0 0;
          }
        }
      }
    }
  }
  .list-material {
    position: relative;
    z-index: 2;
    @include displayflex;
    flex-flow: column nowrap;
    justify-content: space-between;
      @media(min-width: $tablet) {
        flex-flow: row wrap;
      }
    /* Box */

    .box {
      @include flex(0 1 auto);
      text-align: left;
      width: 100%;
      @include transition(all .23s ease-in-out);

      @media(min-width: $tablet) {
        margin: 0;
        padding: 3rem;
      }

      h3 {
        a {
          padding: 2rem 0 0 0;
          position: relative;
          display: inline-block;
          text-align: left;
          color: $white;
          text-transform: initial;
          font-family: $fontheadingbold;
        }
      }
      .date {
        font-style: italic;
        margin: 0 0 1rem 0;
      }

      /* img */
      .img-wrapper {
        height: 24rem;
        position: relative;
        margin: 0 0 2rem 0;
        .img {
          width: 100%;
          margin: 0 auto;
          height: 24rem;
          // @include flex(0 1 64%);
          background-size: 100%;
          background-position: center;
        }
        
        .img-overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 1;
          @include transition(all .23s ease-in-out);
          &:hover {
            opacity: 0;
          }
          background: $gradient;
        }
      }
    }
  }
  .four-columns {
    text-align: left;

    .list-material {
      .box {
        @media(min-width: $tablet) {
          flex-flow: column nowrap;
          display: flex;
          width: 25%;
          margin: 0;
          padding: 1rem;
        }

        .heading {
          font-size: 2.4rem;
          line-height: 1;
          margin-bottom: 1rem;
          text-transform: uppercase;
          color: $white;
          font-family: $fontheadingbold;
        }

        .text-wrapper {
          margin-top: auto;
        }
      }

      .btn {
        margin: 1rem 0 2rem 0;
        @media(min-width: $tablet) {
          margin: 1rem 0 0 0;
        }
      }

      .box-icon {
        max-width: 7rem;
        margin-top: 2rem;
        margin-bottom: 3rem;
      }
      
      /* img */
      .img-wrapper {
        height: 22rem;
        position: relative;
        margin: 0 0 2rem 0;
        .img {
          width: 100%;
          margin: 0 auto;
          height: 22rem;
          background-size: 100%;
          background-position: center;
        }
      }
    }
  }
}