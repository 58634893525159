//Search
.menu-active {
  .search-wrapper {
    display: block;
    position: relative;
    z-index: 334;
  }
}

.search-wrapper {
  position: absolute;
  float: left;
  margin: 0;
  top: 6rem;
  display: none;
  width: 100%;
  text-align: center;
  @media (min-width: $laptop) {
    top: 0;
    position: relative;
    display: inline-block;
    width: auto;
  }

  input[type=search] {
    background: $blue url(assets/images/icons/search.svg) no-repeat right 1rem center;
    padding: .8rem 1rem .9rem 2rem;
    width: 80%;
    border: .1rem solid $blue;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    outline: none;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    margin: 0 0 2rem 0;
    z-index: 999;
    font-size: 1.3rem;
    color: $white;

    @media (min-width: $laptop) {
      width: .5rem;
    }

  }
  input[type=search]:focus {
    width: 13rem;
    border: .1rem solid $green;
    margin: 0 1rem 0 0;
    -webkit-box-shadow: 0 0 .5rem rgba(109,207,246,.5);
    -moz-box-shadow: 0 0 .5rem rgba(109,207,246,.5);
    box-shadow: 0 0 .5rem rgba(109,207,246,.5);
  }

  input:-moz-placeholder {
    color: #999;
  }
  input::-webkit-input-placeholder {
    color: #999;
  }

  #search input[type=search] {
    width: 80%;
    margin: 2rem;
    padding-right: 1rem;
    @media (min-width: $laptop) {
      margin: 0;
      padding-right: 1rem;
      width: 1.5rem;
    }
    cursor: pointer;
  }
  #search input[type=search]:hover {
    border: .1rem solid $blue;
  }
  #search input[type=search]:focus {
    width: 85%;
    padding-right: 3.2rem;
    margin-left: 1rem;
    color: $white;
    cursor: auto;
    border: .1rem solid $green;

    @media (min-width: $laptop) {
      width: 13rem;
    }
  }
  #search input:-moz-placeholder {
    color: $white;
    @media (min-width: $laptop) {
      color: transparent;
    }
  }
  #search input::-webkit-input-placeholder {
    color: $white;
    @media (min-width: $laptop) {
      color: transparent;
    }
  }
}