/* General stuff
–––––––––––––––––––––––––––––––––––––––––––––––––– */
a {
  text-decoration: none;
  color: $green;
}
// hr
hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-width: 0;
  border-top: 0.1rem solid #e1e1e1;
}

// Code
code {
  font-size: 90%;
  margin: 0 0.2rem;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
  border: 0.1rem solid #e1e1e1;
  border-radius: 0.4rem;
  background: #f1f1f1;
}
pre > code {
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}

// img
img {
  height: auto;
  &.responsive {
    width: 100%;
  }
}

// clearfix
.row:before,
.row:after,
.container:before,
.container:after,
.clearfix:before,
.clearfix:after {
  display: table;
  clear: both;
  content: " ";
}

// responsive video
.videoWrapper {
  position: relative;
  height: 0;
  margin-bottom: 3rem;
  padding-top: 2.5rem;
  padding-bottom: 56.25%;
  video {
    width: 100% !important;
    height: auto !important;
  }
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

// list
ul {
  list-style: disc inside;
}

ol {
  list-style: decimal inside;
}

ol,
ul {
  margin-top: 0;
  padding-left: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
  font-size: 90%;
  margin: 1.5rem 0 1.5rem 3rem;
}

li {
  margin-bottom: 0.5rem;
}

$white: #fff;
$blue: #333740;
$grey: #d7d8d6;
$green: #5cac71;
$opacityBlack: rgba(0, 0, 0, 0.3);

section {
  position: relative;
}
.black {
  background-color: $black;
}
.primary {
  background-color: $blue;
}
.secondary {
  background-color: $green;
  color: $black;

  .green-heading {
    color: $black;
  }
  .btn {
    background-color: $black;
  }
}
.tertiary {
  background-color: $grey;
}

.green-heading {
  font-size: 2rem;
  color: $green;
  font-family: $fontprimary;
  text-transform: uppercase;
}

.modal-trigger {
  cursor: pointer;
}

.no-page-found {
  display: flex;
  flex-direction: column;
  height: 60vh;
  padding: 0 3rem;
  align-items: center;
  justify-content: center;

  .no-page-text {
    font-size: 1.8rem;
    font-weight: bold;
    color: $green;
  }
}

.pdf-item {
  list-style: none;

  a {
    color: $lightgrey;
  }
  &:before {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    margin-bottom: -0.9rem;
    content: "";
    border-radius: 0;
    background-color: transparent;
    background-image: url(assets/images/icons/download.svg);
    background-repeat: no-repeat;
    background-position: center;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.search-wrapper-content {
  padding: 5rem;
  color: $white;
  background: $blue;

  input[type="search"] {
    font-size: 1.6rem;
    color: $white;
    border: 0.1rem solid $lightgrey;
    border-radius: 0;
    outline: none;
    background-color: transparent;
  }
  input:-moz-placeholder {
    color: $lightgrey;
  }
  input::-webkit-input-placeholder {
    color: $lightgrey;
  }
}

/* Classes from lazyload js */
.lazyload,
.lazyloading {
  opacity: 0;
  @include transition(0.2s ease-in-out !important);
}

.lazyloaded:not(.boxes-single) {
  opacity: 1;
  @include transition(0.2s ease-in-out !important);
}
