.table {

  .table-wrap {
    clear: both;
    position: relative;
    z-index: 2;
    padding: 12rem 0;
    overflow-x: auto;

    @media(min-width: $tablet) {
      padding: 12rem 0;
    }

  table {
    /* margin-bottom: 3rem; */
    /* Avoid scrollbar when not necessary */
    width: 99.9%;
    /* General */
    th,
    td {
        padding: 1.5rem 1.3rem;
        text-align: left;
        font-weight: normal;
        line-height: 1.1;
        border-bottom: 0.1rem solid $black;
    }
    /* Thead */
    thead {
        background: $blue;
        font-family: $fontheading;
        color: $green;
        padding: 1rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        tr {
          th {
            padding: 3rem 2rem;
            border: 0.1rem solid $black;
          }
        }
    }
    /* Tbody */
    tbody {
      background: $blue;
        tr {
            @include transition(.08s ease-in-out);
            background: $blue;
            &:hover {
                background: $blue;
            }
            td {
                border: 0.1rem solid $black;
            }
        }
    }
  }
  caption {
    text-align: left;
    font-family: $fontheading;
  }
    
  }
  h2 {
    margin: 0 0 2rem 0;
  }
  .ingress {
    max-width: 70rem;
    margin: 0 auto;
    padding: 0 0 4rem 0;
  }
  .btn {
    margin: 4rem 0 0 0;
  }
}