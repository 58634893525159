/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-wrapper {
  width: 100%;
  margin: 0 auto;
}

.owl-prev i,
.owl-next i {
  transform: scale(1, 6);
  color: #ccc;
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme {

  .owl-prev {
    position: absolute;
    top: 38%;
    left: -0!important;
    display: block !important;
    width: 4rem;
    height: 4rem;
    transform: rotate(90deg);
    border: 0 solid black;
    background: url("assets/images/icons/arrow-down.svg") no-repeat 0.4rem center;
    background-size: 2rem 2rem;
  }

  .owl-next {
    position: absolute;
    top: 38%;
    right: 0!important;
    display: block !important;
    width: 4rem;
    height: 4rem;
    transform: rotate(-90deg);
    border: 0 solid black;
    background: url("assets/images/icons/arrow-down.svg") no-repeat 1.5rem center;
    background-size: 2rem 2rem;
  }
}

.owl-theme .owl-nav {
  margin-top: 1rem;
}

.owl-theme .owl-nav [class*=owl-] {
  font-size: 1.4rem;
  display: inline-block;
  margin: 0.5rem;
  padding: 0.4rem 0.7rem;
  cursor: pointer;
  color: #FFF;
  border-radius: 0.3rem;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  text-decoration: none;
  color: #FFF;
}

.owl-theme .owl-nav .disabled {
  cursor: default;
  opacity: .5;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin: 0 auto;
}
.owl-theme .owl-dots {
  margin-bottom: -4rem;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0.5rem 0.3rem;
  transition: opacity .2s ease;
  border-radius: 3rem;
  background: $darkgrey;
  -webkit-backface-visibility: visible;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $green;
}

.owl-quotes .owl-dots .owl-dot span {
  background: $white;
}

.owl-service-people .owl-dots .owl-dot span,
.owl-quotes .owl-dots .owl-dot span {
  display: none !important;
  background: $white;

  @media (min-width: $laptop) {
    display: block !important;
  }
}

.owl-service-people .owl-dots .owl-dot.active span,
.owl-service-people .owl-dots .owl-dot:hover span {
  background: $green;
}