.video-gallery {

  h2 {
    margin: 0 0 4rem 0;
  }

  .slider-wrapper {
    max-width: 85rem;
    margin: 0 auto;
    padding: 6rem 2rem;
    @media (min-width: $tablet) {
      padding: 10rem 0;
    }

    .gallery,
    .preview-pagination-slider {
      white-space: nowrap;
    }

    .gallery {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: auto;
      z-index: 1;
    }

    .gallery-pagination {
      position: relative;
      margin-bottom: 5rem;
      padding-top: 1rem;

      @media (min-width: $desktop-m) {
        padding-top: 20px;
      }

      .slide {
        cursor: pointer;
        outline: none;

        img {
          padding: .4rem;
          opacity: 0.6;
          @include transition(all .3s ease);

          &:hover {
            opacity: 0.9;
          }
        }

        &.slick-current {
          img {
            opacity: 1;
          }
        }
      }

      .slide-arrow {
        font-size: 2rem;
        line-height: 32px;
        position: absolute;
        z-index: 22;
        top: 50%;
        width: 3.2rem;
        height: 3.2rem;
        transform: translateY(-50%);
        text-align: center;
        letter-spacing: 0;

        &:before {
          display: none;
        }

        &:hover {
          background: lighten($blue, 15);
        }
      }

      .preview-prev {
        left: 0;

        &:after {
          position: absolute;
          bottom: .9rem;
          left: 1.2rem;
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          content: '';
          transform: rotate(-135deg);
          vertical-align: top;
          color: $green;
          border-width: .2rem .2rem 0 0;
          border-style: solid;
        }
      }

      .preview-next {
        right: 0;

        &:after {
          position: absolute;
          right: 1.2rem;
          bottom: .9rem;
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          content: '';
          transform: rotate(45deg);
          vertical-align: top;
          color: $green;
          border-width: .2rem .2rem 0 0;
          border-style: solid;
        }
      }
    }

    .preview-pagination-slider {
      font-size: 0;
      line-height: 0;
      position: relative;
      overflow: hidden;
      margin: 0;
      letter-spacing: -.4rem;

      .slide {
        width: 33.33%;
        height: 16rem;

        img {
          width: 100%;
        }
      }

      .slick-list {
        overflow: hidden;
      }
    }

  }

  .overlay-class {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background: $gradient;
    @include transition(all .63s ease-in-out);
    &:before {
      position: absolute;
      content: '';
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $gradient;
      @include transform(scale(1.03));
    }
  }

  .play-slide-video {
    position: absolute;
    z-index: 4;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 6rem;
    height: 6rem;
    margin: -2.5rem;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6rem 6rem;

  }
}