// Site overlay to color overlay site
#menu-overlay {
  position: fixed;
  display: none;
  top: 7rem;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background-color: rgba(0, 0, 0, .4);
  .menu-active  & {
    display: block;
    -webkit-animation: fadeIn 250ms ease-in-out both;
    animation: fadeIn 250ms ease-in-out both;
  }
  .login-active  & {
    display: block;
    -webkit-animation: fadeIn 250ms ease-in-out both;
    animation: fadeIn 250ms ease-in-out both;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}