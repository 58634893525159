.image-block {
  padding: 4rem 2rem;

  img {
    width: 100%;
  }
  
  @media(min-width: $tablet) {
    padding: 8rem 0;
  }
}