.team-wrapper {
  padding: 6rem 0;
  @media(min-width: $tablet) {
    padding: 10rem 0;
  }

  .ingress {
    max-width: 70rem;
    margin: 0 auto;
    padding: 0 0 3rem 0;
  }

  .team {
    position: relative;
    z-index: 2;
    @include displayflex;
    flex-flow: column nowrap;
    align-items: stretch;
      @media(min-width: $tablet) {
        flex-flow: row wrap;
      }
    /* Box */
    .box {
      @include flex(0 1 auto);
      padding: 2.5rem;
      text-align: left;
      width: 100%;
      @include transition(all .23s ease-in-out);

      @media(min-width: $tablet) {
        @include flex(0 1 33.33%);
        width: 33.33%;
        margin: 0;
        padding: 3.5rem;
      }
      @media(min-width: $desktop-m) {
        padding: 2.5rem;
      }

      .text-wrapper {
        position: relative;
        width: 100%;
        padding: 2rem;
        opacity: 0;
        z-index: 1;
        @include transition(all .23s ease-in-out);
        
        @media(min-width: $tablet) {
          padding: 3rem;
        }
        @media(min-width: $desktop) {
          padding: 5rem;
        }
        .name {
          text-transform: uppercase;
          font-weight: bold;
          font-family: $fontheadingbold;
          font-size: 1.9rem;
          color: $white;
        }
        .position {
          font-size: 1.8rem;
          margin: 0 0 2rem 0;
          color: $white;
          text-transform: uppercase;
        }
        .text {
          color: $lightblue;
          font-size: 1.7rem;
          line-height: 1.2;
        }
        p {
          margin: 0;
        }
        .team-icon {
          display: inline-block;
          margin: 3rem 0 0 0;
        }

        .mail {
          &:before {
            content: '';
            position: relative;
            display: inline-block;
            width: 2.3rem;
            height: 2.3rem;
            margin: 0 1rem 0 0;
            background-image: url(assets/images/icons/mail.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 2rem 2rem;
          }
        }
        .phone {
          color: $green;
          &:before {
            content: '';
            position: relative;
            display: inline-block;
            width: 2.3rem;
            height: 2.3rem;
            margin: 0 1rem 0 0;
            background-image: url(assets/images/icons/phone.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 2rem 2rem;
          }
        }
        .linkedin {
          &:before {
            content: '';
            position: relative;
            display: inline-block;
            width: 2.3rem;
            height: 2.3rem;
            margin: 0 1rem 0 0;
            background-image: url(assets/images/icons/linkedin.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 2rem 2rem;
          }
        }
      }
      /* img */
      .img-wrap {
        position: relative;
        width: 100%;
        min-height: 24rem;
        height: 100%;
        background-position: center;
          
          .img-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 1;
            @include transition(all .23s ease-in-out);
          }

          &:hover {
            .img-overlay {
              background: rgba(51, 55, 64, 0.8);
            }
            .text-wrapper {
              opacity: 1;
            }
          }
        }
      }
    }
  }