.text-link {
  .smaller-width {
    max-width: 80rem;
  }
  .text-link-wrap {
    position: relative;
    z-index: 2;
    padding: 3rem 2rem;
    align-items: center;
    justify-content: center;
    @include displayflex;
    flex-flow: column nowrap;

    @media(min-width: $tablet) {
      padding: 12rem 2rem;
      flex-flow: row wrap;
    }

    /* Box */
    .box-wrap {
      z-index: 3;
      @include flex(0 1 auto);

      @media(min-width: $tablet) {
        @include flex(0 auto 100%);
        width: 100%;
        margin: 0;
      }
    }

    .btn {
      background: none;
      color: $green;
    }

    h3 {
      font-family: $fontheadingbold;
      font-size: 1.7rem;
      color: $white;
      text-transform: initial;
      
      @media(min-width: $tablet) {
        font-size: 2rem;
      }
    }

    .text {
      font-family: $fontheadingbold;
      font-size: 1.8rem;
      line-height: 1;
      color: $green;
      margin: 1rem 0 2rem 0;

      @media(min-width: $tablet) {
        font-size: 2rem;
      }
    }
  }
}