.gallery-wrapper {
  padding: 4rem 0 8rem 0;

  @media(min-width: $tablet) {
    padding: 8rem 0 12rem 0;
  }

  .gallery {
    position: relative;
    z-index: 2;

    .slider-wrapper {
      max-width: 125rem;
      margin: 0 auto;

      .slick-gallery {
        position: relative;
        z-index: 1;
        overflow: hidden;
        width: 100%;
        height: auto;
      }

      .slide-arrow {
        font-size: 2rem;
        line-height: 32px;
        position: absolute;
        z-index: 22;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        letter-spacing: 0;

        &:before {
          display: none;
        }

        &:hover {
          // background: lighten($blue, 15);
        }
      }

      .preview-prev {
        left: 0;

        &:after {
          position: absolute;
          bottom: .9rem;
          left: 1.2rem;
          display: inline-block;
          width: 3.4rem;
          height: 3.4rem;
          content: '';
          transform: rotate(90deg);
          vertical-align: top;
          color: $green;
          background: url("assets/images/icons/arrow-down.svg") no-repeat center;
          background-size: 2rem 2rem;
        }
      }

      .preview-next {
        right: 0;

        &:after {
          position: absolute;
          right: 1.2rem;
          bottom: .9rem;
          display: inline-block;
          width: 3.4rem;
          height: 3.4rem;
          content: '';
          transform: rotate(-90deg);
          vertical-align: top;
          color: $green;
          background: url("assets/images/icons/arrow-down.svg") no-repeat center;
          background-size: 2rem 2rem;
        }
      }

      .slick-dots {
        display: flex;
        margin: 0;
        padding: 1rem 0;
        list-style-type: none;
        justify-content: center;

        li {
          margin: 0 0.25rem;
        }

        button {
          display: block;
          width: 1rem;
          min-width: auto;
          height: 1rem;
          padding: 0;
          text-indent: -9999px;
          border: none;
          border-radius: 100%;
          background-color: $black;
        }

        li.slick-active button {
          background-color: $green;
        }

      }
    }

    /* Box */
    .box {
      width: 100%;
      padding: 2rem;
      text-align: left;
      @include flex(0 1 auto);
      @include transition(all .23s ease-in-out);

      @media(min-width: $laptop) {
        margin: 0;
        padding: 3rem;
      }

      img {
        width: 100%;
        max-width: 70rem;
        margin: 0 auto;
      }

      .video-container {
        max-width: 70rem;
        margin: 0 auto;
      }
    }

    .border-bottom {
      display: block;
      width: 100%;
      height: .1rem;
      margin-top: -4rem;
      border-bottom: .1rem solid $green;
    }
  }

  .bottom-background {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 50%;
  }
}