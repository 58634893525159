.cta {
  background: $green;

  .smaller-layout {
    max-width: 80rem;

    .cta-wrap {
      padding: 3rem 0;
    }

  }
  .cta-wrap {
    padding: 6rem 0;
    @media(min-width: $tablet) {
      padding: 12rem 0;
      @include flex(0 auto 100%);
      width: 100%;
    }
  }
  .cta-wrap-small {
    padding: 3.3rem 0 2.8rem 0;
    h2 {
      font-size: 3rem; 
      
      @media(min-width: $tablet) {
        float: left;
      }
    }
    .btn {
      margin: .3rem 0 0 0;
      @media(min-width: $tablet) {
        float: left;
        margin: .3rem 0 0 3rem;
      }
    }
    /* Box */
    .box-wrap {
      width: auto;
      @media(min-width: $tablet) {
        width: auto;
      }
    }
  }
  .cta-wrap,
  .cta-wrap-small {
    position: relative;
    z-index: 2;
    align-items: center;
    justify-content: center;
    @include displayflex;
    flex-flow: column nowrap;

    @media(min-width: $tablet) {
      flex-flow: row wrap;
    }

    /* Box */
    .box-wrap {
      z-index: 3;
      color: $black;
      @include flex(0 1 auto);

      @media(min-width: $tablet) {
        margin: 0;
      }
    }

    .btn {
      background: $black;
      color: $white;
    }

    h2 {
      font-family: $fontheadingbold;
      font-size: 2.5rem;
      color: $black;
      
      @media(min-width: $tablet) {
        font-size: 4.5rem;
      }
    }

    h3, h4 {
      text-transform: initial;
    }

    .text {
      font-family: $fontheadingbold;
      font-size: 1.8rem;
      line-height: 1;
      color: $black;
      margin: 1rem 0 2rem 0;

      @media(min-width: $tablet) {
        font-size: 2rem;
      }
    }
  }
}