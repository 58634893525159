.text-block {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  .text-link-wrap {
    position: relative;
    z-index: 2;
    padding: 3rem 2rem 2rem 2rem;
    align-items: center;
    justify-content: center;
    @include displayflex;
    flex-flow: column nowrap;

    @media(min-width: $tablet) {
      padding: 6rem 2rem 5rem 2rem;
      flex-flow: row wrap;
    }

    &.center {
      .box-wrap {
        text-align: center;
        
        .col-2 {
          width: 100%;
        }
      }
    }

    /* Box */
    .box-wrap {
      text-align: left;
      @media(min-width: $tablet) {
        display: flex;
        width: 100%;
        margin: 0;
      }

      &.reversed {
        @media(min-width: $tablet) {
          flex-direction: row-reverse;
        }
        .col-1 {
          @media(min-width: $tablet) {
            margin: 0 6% 0 6%;
          }
        }
      }

      .col-1 {
        @media(min-width: $tablet) {
          margin: 0 6% 0 0;
          width: 26%;
          min-width: 30rem;
          display: inline-block;
          hyphens: auto;
          word-break: break-all;
        }
        &.heading-background {
          background-repeat: no-repeat;
          background-position: top left;
          background-color: transparent;
          padding: 3rem 0;
          @media(min-width: $tablet) {
            padding: 0;
          }
        }
      }

      .col-2 {
        @media(min-width: $tablet) {
          width: 69%;
          display: inline-block;
        }
        p {
          font-size: 1.5rem;
          @media(min-width: $tablet) {
            font-size: 1.7rem;
          }
        }
        li {
          list-style: none;
          margin-left: 1.8rem;
          position: relative;

          &:before{
            content: "";
            position: absolute;
            left: -1.8rem;
            top: 0.9rem;
            width: 0.7rem;
            height: 0.7rem;
            -moz-border-radius: .75rem;
            -webkit-border-radius: .75rem;
            border-radius: .75rem;
            background-color: $grey;
          }
        }
      }
    }

    .green-icon {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }

    .text {
      font-family: $fontheadingbold;
      font-size: 1.8rem;
      line-height: 1;
      color: $green;
      margin: 1rem 0 2rem 0;

      @media(min-width: $tablet) {
        font-size: 2rem;
      }
    }
  }
}