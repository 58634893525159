/* Search results page */
.search-wrap {
  padding: 10rem 0;
	.search-item {
		margin: 0 0 1.3rem 0;

		.small-wrap {
			display: block;

			p {
				font-size: 1.2rem;
				display: inline-block;
				margin: 0;
				opacity: 0.7;

				&:first-of-type {
					font-size: 1.1rem;
				}
			}
		}

		h4 {
			display: inline-block;
			margin: 0.2rem 0 0 0;
		}
	}
}