// triggers in menu
.menu-actions {
  position: absolute;
  z-index: 999;
  display: inline-block;
  right: 0;
  top: 0;
  @media(min-width: $laptop) {
    display: none;
  }
}

// foldout nav
#main-menu-wrapper {
  position: fixed;
  z-index: 333;
  top: 7rem;
  right: 0;
  bottom: 0;
  left: auto;
  display: block;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  transition: all 0.2s ease;
  transform: translateX(100%);
  background: rgba(0, 0, 0, 0.85); 

  @media (min-width: $laptop) {
    width: 50rem;
  }

  * {
    text-decoration: none;
    color: $white;
    // @include transition(0.24s ease-in-out);

    &:hover {
      color: #ccc;
    }
  }

  .main-menu {
    padding: 9rem 2rem 2rem 2rem;

    li {
      position: relative;
      font-size: 1.4rem;
      display: block;
      margin-bottom: 0rem;
      padding: 1rem 0;
      text-align: center;
      text-transform: uppercase;
      border-bottom: .1rem solid rgba(255, 255, 255, 0.1); 
      // @include transition(0.24s ease-in-out);

      @media(min-width: $laptop) {
        font-size: 1.8rem;
      }

      &.active {
        border-bottom: .1rem solid rgba(255, 255, 255, 0.1); 
      }
      &:last-child {
        border: none;
      }
    }

    // first level sub menu
    .active {
      opacity: 1;
    }

    .sub-menu {
      padding-top: 2rem;
      display: none;
      margin: 0;

      &.showMe {
        display: block;
      }

      li {
        font-size: 1.5rem;
        letter-spacing: 0.05rem;

        a {
          color: rgba($white, 0.7);
        }

        &:first-child {
          padding-top: 2.5rem;
        }

        &:last-child {
          padding-bottom: 2.7rem;
          border: none;
        }
        // Second level sub
        ul {
          display: none;

          &.showSub {
            display: block;
          }

          li {
            font-size: 1.3rem;
            padding-bottom: 0.51rem;

            &:first-child {
              padding-top: 0.51rem;
            }

            &:last-child {
              padding-bottom: 0.5rem;
            }
          }
        }
      }

    }
  }

  // foldout
  .menu-active & {
    transform: translateX(0);
  }

  .menuArrow {
    position: absolute;
    top: .2rem;
    right: 0;
    width: 3.4rem;
    height: 3.4rem;
    content: "";
    cursor: pointer;
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    /* Safari */
    transform: rotate(0deg);
    background-image: url(assets/images/icons/arrow-down.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.6rem 1.6rem;
    @include transition(0.2s ease-in-out);

    &:hover {
      transform: scale(1.08);
    }

    &.open {
      /* IE 9 */
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      /* Safari */
      transform: rotate(180deg);
      background-image: url(assets/images/icons/arrow-down.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1.6rem 1.6rem;
    }
  }

  .social-icons {
    position: absolute;
    bottom: 0;
    left: 0; 
    right: 0; 
    top: auto;
    margin-left: auto; 
    margin-right: auto; 
    li { 
      text-align: center;
      float: none;
      a {
        color: $green;
      }
    }
  }
}

// Hamburger
.menu-trigger {
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 7rem;
  cursor: pointer;

  .burger {
    margin: 1.2rem;

    &,
    &:before,
    &:after {
      position: absolute;
      left: 0;
      width: 2.4rem;
      height: 0.4rem;
      background: $green;
      @include border-radius(.8rem);
    }

    &:before,
    &:after {
      content: "";
    }

    & {
      top: 50%;
      margin-top: -2px;
      transition: transform 115ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &:before {
      top: -8px;
      width: 2.4rem;
      transition: top 115ms ease 0.12s, opacity 115ms ease;
    }

    &:after {
      bottom: -8px;
      width: 2.4rem;
      transition: bottom 115ms ease 0.12s, transform 115ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    .menu-active & {
      & {
        transition-delay: 120ms;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(45deg);
      }

      &:before {
        top: 0;
        width: 2.4rem;
        transition: top 115ms ease, opacity 115ms ease 120ms;
        opacity: 0;
      }

      &:after {
        bottom: 0;
        width: 2.4rem;
        transition: bottom 115ms ease, transform 115ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
      }
    }
  }
}