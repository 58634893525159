.box-img-link-image-reverse {
  max-width: 130rem;
  margin: 0 auto;

  &.reverse {
    .box-img-link-image-wrap {
      flex-direction: column;
      @media (min-width: $tablet) {
        flex-direction: row-reverse;
      }
    }
  }
  .box-img-link-image-wrap {
    position: relative;
    padding: 3rem 0;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    @include displayflex;
    @media (min-width: $tablet) {
      padding: 5rem 2rem;
      flex-flow: row wrap;
    }

    /* Box */
    .box-wrap {
      padding: 2rem;
      text-align: left;
      @include flex(0 1 auto);
      width: 100%;
      @include transition(all 0.23s ease-in-out);

      @media (min-width: $tablet) {
        width: 50%;
        margin: 0;
        padding: 4rem;
        @include flex(0 auto 50%);
      }
    }

    .box {
      .img {
        min-height: 20rem;
        @media (min-width: $tablet) {
          min-height: 30rem;
        }
      }
    }

    .btn {
      color: $green;
      background: none;
    }
    h2 {
      font-family: $fontheadingregular;
      font-size: 2rem;
      color: $green;
    }
    .ingress {
      font-family: $fontheadingregular;
      line-height: 1;
      margin: 1rem 0 2rem 0;
      text-transform: uppercase;
      color: $white;
      @media (min-width: $tablet) {
        font-size: 2rem;
      }
    }

    .request-btn {
      font-size: 1.6rem;
      margin: 0 4rem 0 4rem;
    }
  }
  /* img */
  .img {
    width: 100%;
    height: 100%;
    min-height: 30rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
